export const formatCurrency = (value) => {
    if (typeof value !== 'number') {
        return value
    }
    var formatter = new Intl.NumberFormat('en-US')
    return formatter.format(value)
}
export const hiddenText = (text, keepText = 3) => {
    if (!text || text.length <= keepText) {
        return text
    }

    const maskedPart = '*'.repeat(text.length - keepText)
    const lastCharacters = text.slice(-keepText)

    return maskedPart + lastCharacters
}
